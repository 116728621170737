.wrap {
    font-style: 16px;
}

.detailsBtn {
    border-bottom: 1px dotted #fff;
    cursor: pointer;
    font-weight: 300;
    color: #ccc;
}

.detailsWrap {
    font-style: 14px;
    margin-top: 2px;
}

@media (max-width: 768px) {
    .detailsWrap {
        margin-top: 10px;
    }
}