body {
  font-family: 'Open Sans', sans-serif;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.firstBigLetter .ant-select-item-option .ant-select-item-option-content:first-letter {
  text-transform: uppercase;
}

.firstBigLetter {
  display: block;
}

.firstBigLetter::first-letter {
  text-transform: uppercase !important;
}

.firstBigLetter .ant-select-selection-item:first-letter {
  text-transform: uppercase;
}

.ant-ribbon {
  top: 4px !important;
}

body {
  background-color: #141413 !important;
}

.metaLabel {
  font-weight: 500 !important;
}

.metaWrap {
  color: #ccc !important;
  font-size: 14px;
}

.ant-ribbon {
  top: -5px !important;
}