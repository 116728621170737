.mainHeader {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}

.mainHeader .select {
  color: #2D72F6;
  display: block;
}

.headerWrap {
  margin-top: 60px;
}

.description {
  font-size: 18px;
  text-align: center;
}

.listWrap {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 780px;
}

.spinWrap {
  width: 100%;
  height: calc(100vh - 89px - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 580px) {
  .mainHeader {
    font-size: 32px;
  }

  .headerWrap {
    margin-top: 20px;
  }
}